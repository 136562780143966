// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';
/**
 * gsap
 */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function initSlickSlider() {
  if ($(window).width() <= 460) {
    if (!$('.white-papers-slider').hasClass('slick-initialized')) {
      $('.white-papers-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
      });
    }
  } else {
    if ($('.white-papers-slider').hasClass('slick-initialized')) {
      $('.white-papers-slider').slick('unslick');
    }
  }
}
/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  if ($('.services-banner').length) {
    let $section = $('.services-banner');
    let $title = $('.services-banner__heading');

    if ($section.length && $title.length) {
      $(window).on('scroll', function () {
        let scrollPosition = $(window).scrollTop();

        if (scrollPosition < 10) {
          $section.css('background-color', '#000000');
          $title.css('color', '#ffffff');
        } else {
          $section.css('background-color', '#ffffff');
          $title.css('color', '#000000');
        }
      });
    }
  }

  if ($('.white-papers-slider').length) {
    initSlickSlider();
  }

  gsap.registerPlugin(ScrollTrigger);
  // const tl = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: '.home-hero',
  //     start: 'top 90',
  //     // endTrigger: '.home-firm',
  //     end: 'bottom 80%',
  //     scrub: 0.1,
  //     pin: true,
  //     pinSpacing: true,
  //     // duration: { min: 4 },
  //     // delay: 0,
  //     // toggleActions: 'restart none reset none',
  //     // markers: { startColor: 'green', endColor: 'red' },
  //     // onUpdate: (self) => scaleContainer(self.progress), // Custom scaling function
  //   },
  //   // delay: 1,
  //   ease: 'power1.inOut',
  // });
  // tl.to('.home-video__video-wrap', {
  //   scaleY: 1,
  //   scaleX: 1,
  //   opacity: 1,
  //   translateY: '-500px',
  //   borderRadius: '0px'
  // });
  // const firstContainerHeight = document.querySelector(".home-hero__wrapper").offsetHeight;
  // console.log(firstContainerHeight);
  if (window.innerWidth >= 550) {
    gsap.to('.home-video__video-wrap', {
      scale: 1,
      scrollTrigger: {
        trigger: '.home-hero__wrapper',
        start: '50 top',
        end: 'bottom 700',
        pinSpacing: false,
        pin: true,
        scrub: true,
        ease: 'power1.inOut',
        // markers: { startColor: 'green', endColor: 'yellow' },
      },
    });
  }

  if (window.innerWidth < 550) {
    gsap.to('.home-video__video-wrap', {
      scale: 1,
      scrollTrigger: {
        trigger: '.home-hero__wrapper',
        start: 'top 50',
        end: 'bottom 700',
        pinSpacing: false,
        pin: true,
        scrub: true,
        ease: 'power1.inOut',
        // markers: { startColor: 'green', endColor: 'yellow' },
      },
    });
  }
  //Attorney section slider
  $('.testimonials__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rows: false,
  });
  //Resources slider
  $('.resources__slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  // Hide label on focus
  let textareaField = document.querySelectorAll('.contact__form .gfield');
  textareaField.forEach(function (field) {
    if (field.querySelector('textarea') || field.querySelector('input')) {
      let fieldInput;
      if (field.getAttribute('id') == 'field_1_11') {
        fieldInput = field.querySelector('textarea');
      } else {
        fieldInput = field.querySelector('input');
      }
      fieldInput.addEventListener('focus', function () {
        if (this.value == '') {
          field.querySelector('.gfield_label').classList.add('is-rise');
        }
      });
      fieldInput.addEventListener('focusout', function () {
        if (this.value == '') {
          field.querySelector('.gfield_label').classList.remove('is-rise');
        }
      });
    }
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      if ($('body').hasClass('no-scroll')) {
        $('body').removeClass('no-scroll');
      } else {
        $('body').addClass('no-scroll');
      }
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('body').removeClass('no-scroll');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  if ($('.white-papers-slider').length) {
    initSlickSlider();
  }
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
if ($('.home-hero').length > 0) {
  function isElementAbove90px($element) {
    const elementTop = $element.offset().top - $(window).scrollTop();
    return elementTop < 90;
  }

  const $myElement = $('.header-button');
  const $navButton = $('.nav-button');
  const $logo = $('.header-logo');
  $(window).on('scroll', function () {
    // jQuery code goes here
    if (isElementAbove90px($myElement)) {
      $navButton.addClass('is-active');
      $logo.addClass('centered');
    } else {
      $navButton.removeClass('is-active');
      $logo.removeClass('centered');
    }
  });
}

function initTextareaFieldHandlers() {
  let textareaField = document.querySelectorAll('.contact__form .gfield');

  textareaField.forEach(function (field) {
    let fieldInput =
      field.querySelector('textarea') || field.querySelector('input');

    if (fieldInput) {
      fieldInput.addEventListener('focus', function () {
        if (this.value === '') {
          field.querySelector('.gfield_label').classList.add('is-rise');
        }
      });

      fieldInput.addEventListener('focusout', function () {
        if (this.value === '') {
          field.querySelector('.gfield_label').classList.remove('is-rise');
        }
      });
    }
  });
}

function checkAfterSubmit() {
  let textareaFields = document.querySelectorAll('.contact__form .gfield');

  textareaFields.forEach(function (field) {
    let inputField = field.querySelector('textarea, input[type="text"], input[type="email"]');

    if (inputField && inputField.value.trim() !== '') {

      let label = field.querySelector('.gfield_label');
      if (label) {
        label.classList.add('is-rise');
      }
    }
  });
}


document.addEventListener('DOMContentLoaded', function () {
  initTextareaFieldHandlers();
});

jQuery(document).on('gform_post_render', function () {
  initTextareaFieldHandlers();
  checkAfterSubmit();
});

jQuery(document).on('gform_confirmation_loaded', function () {
  initTextareaFieldHandlers();
  checkAfterSubmit();
});
